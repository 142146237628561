import React from "react"

import { Link, useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionImage from "../components/section-image"

const ServicesEnPage = () => {

  const data = useStaticQuery(graphql`
    query {
      parallax: file(relativePath: { eq: "carousel/hero-4.jpg" }) {
        childImageSharp {
          fluid(quality: 75) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      maritimo: file(relativePath: { eq: "services/maritim.jpg" }) {
        childImageSharp {
          fluid(quality: 75) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      brokerage: file(relativePath: { eq: "services/brokerage.jpg" }) {
        childImageSharp {
          fluid(quality: 75) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      trailer: file(relativePath: { eq: "services/trailer.jpg" }) {
        childImageSharp {
          fluid(quality: 75) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      atorigin: file(relativePath: { eq: "services/at-origin.jpg" }) {
        childImageSharp {
          fluid(quality: 75) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      trains: file(relativePath: { eq: "services/trains.jpg" }) {
        childImageSharp {
          fluid(quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      warehouse: file(relativePath: { eq: "services/warehouse.jpg" }) {
        childImageSharp {
          fluid(quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      specialcargo: file(relativePath: { eq: "services/special-cargo.jpg" }) {
        childImageSharp {
          fluid(quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return(

    <Layout en={true}>
        <SEO title="Services" />

        <section className="container-md pt-5 rounded">
            <div className="d-flex flex-column align-items-start justify-content-center">
              <h1 className="text-dark pb-4">The Pioneer Difference</h1>
              <p className="pb-5 mb-5">Our mission is to provide solutions to our clients while offering exceptional service and setting the standard for professionalism in the logistics industry.</p>
            </div>
        </section>

        <Img fluid={data.parallax.childImageSharp.fluid} className="bg-parallax h-350" alt="Pioneer solutions" />

        <SectionImage 
            // tag=""
            title="Ocean Import-Export"
            paragraph={[
              "Pioneer Solutions retains strong market leadership in the Trans-Pacific Westbound market. With coverage from California and New York, we can effectively handle shipments across the U.S. including all major ports and key inland locations. Our office has vast experience in providing customized solutions for FCL, LCL, consolidated cargo, overweight cargo, oversized cargo, automobiles, metal scrap, plastic scrap, lumber, Agro products, and refrigerated goods.",
              "Pioneer Solutions works closely with the leading shipping lines such as APL, COSCO, CMA CGM, ONE, Hyundai, Yang Ming, MSC, and Maersk in order to provide our customers with the best pricing and equipment availability. In addition, Pioneer Solutions has extensive experience in other value-added services such as filing certificates of origin, cargo fumigation, packaging, and shipping insurance. ",
              "Through our comprehensive agency network covering major ports around the world including Hong Kong, Taiwan, Singapore, Thailand, Malaysia, Indonesia, Vietnam, the Philippines, Korea, Europe, Central and South America, and Australia, we can provide industry leading FCL and LCL import services."]}
            src={<Img fluid={data.maritimo.childImageSharp.fluid} className="rounded mb-auto" alt="Ocean Import-Export" />}
            reverse={true}
            // link={{to: "/servicios", title: "Ver más servicios"}}
            // list={[
            //     "FTL (Full Truck Load).",
            //     "LTL (Less Truck Load).",
            //     "48'/ 53' equipment.",
            //     "Load consolidation, Platform, Low Boy, Bobtail and Oversized cargo"
            // ]}
        />

        <SectionImage 
            // tag=" "
            title="Comprehensive Services at Origin"
            paragraph={[
              "From the moment a shipping order arrives, our experienced staff will handle carrier booking, cargo allocation, trucking and pier services, custom declarations, AMS / ISF, and issuing bills of lading in a seamless process. For transshipment cargo, our highly trained customer support staff will actively track cargo until the shipments arrive at the final destination", 
            ]}
            src={<Img fluid={data.atorigin.childImageSharp.fluid} className="rounded h-100" alt="Comprehensive Services at Origin" />}
            // link={{to: "contacto", title: "Contactar ahora"}}
            // list={[
            //     "Port to Port services.",
            //     "Door to Port services.",
            //     "Customs Filling & Clearance.",
            //     "Refrigerated Containers.",
            //     "(FCL) Full Container Load.",
            //     "(LCL) Less Container Load.",
            //     "Dry cargo, Perishables, Break Bulk, Loose loads and Oversized cargo.",
            //     "Incoterms Compliance and Consulting.",
            // ]}
        />

        <SectionImage 
            // tag=""
            title="Superior Destination Port Service"
            paragraph={[
              "We have extensive experience in handling DDU, DDP, LDP cargoes and handle distributions, whether through our warehouse, trucks, or rail. Through arranging precise door delivery trucking services and maintaining constant communication with the consignee, our staff ensures on-time delivery, and any unexpected contingencies are dealt with before they become an issue."
            ]}
            src={<Img fluid={data.trains.childImageSharp.fluid} className="rounded h-100" alt="Superior Destination Port Service" />}
            reverse={true}
            // link={{to: "/servicios", title: "Ver más servicios"}}
            // list={[
            //     "Train + Truck.",
            //     "Ferro-Mex (Mexican Railroad).",
            //     "KCS (Kansas City Southern).",
            // ]}
        />

        <SectionImage 
            // tag=""
            title="Special Cargo"
            paragraph={[
              "We also have vast experience in handling personal effects, DG cargo, reefer containers, exhibition samples, and oversized / overweight cargo and ensuring their smooth customs declaration and clearance process.",
              "Since then, our expertise in connecting Trans-Pacific trade has enabled us to grow together with our customers. Headquartered in Los Angeles but with a strong global vision, Pioneer Solutions is a leading integrated logistics company comprising of four business segments: NVOCC, Warehousing & Distribution, Customs Clearance & Trade Advisory, and Trucking."
            ]}
            src={<Img fluid={data.specialcargo.childImageSharp.fluid} className="rounded h-100" alt="Special Cargo" />}
            // link={{to: "contacto", title: "Contactar ahora"}}
            // list={[
            //     "Short term storage.",
            //     "Over flow handling.",
            //     "Trans-load service.",
            //     "Commercial packaging.",
            // ]}
        />

        <SectionImage 
            // tag=""
            title="Customs Brokerage & Trade Advisory"
            paragraph={[
              "Pioneer Solutions is a trusted importer with vast experiences in navigating U.S. and import regulations. Our team of experienced professionals seek to be your trusted partner on your import shipments, from answering simple questions to look up a duty code, to classifying HS code to use for your imports, to purchasing an annual bond for your company, we can service your needs from A to Z. We commit to making the entry process as smooth for you as possible so you can focus on your import business."
            ]}
            src={<Img fluid={data.brokerage.childImageSharp.fluid} className="rounded h-100" alt="Customs Brokerage & Trade Advisory" />}
            reverse={true}
            // link={{to: "/servicios", title: "Ver más servicios"}}
            // list={[
            //     "Train + Truck.",
            //     "Ferro-Mex (Mexican Railroad).",
            //     "KCS (Kansas City Southern).",
            // ]}
        />

        <SectionImage 
            // tag=""
            title="Trucking & Inland Transportation"
            paragraph={[
              "At Pioneer Solutions, we understand trucking and drayage is frequently a pain and bottleneck for supply chains. That is why we carefully select our trucking partners and review these partners on a regular basis to hold them up to the highest standards. We partner with only the most reliable and trusted trucking partners to ensure on-time delivery and pickup."
            ]}
            src={<Img fluid={data.trailer.childImageSharp.fluid} className="rounded h-100" alt="Trucking & Inland Transportation" />}
            // link={{to: "contacto", title: "Contactar ahora"}}
            // list={[
            //     "Short term storage.",
            //     "Over flow handling.",
            //     "Trans-load service.",
            //     "Commercial packaging.",
            // ]}
        />

        <SectionImage 
            // tag=""
            title="Warehouse and Distribution"
            paragraph={[
              "Warehousing is key to supply chain management. We can service the entire United States from our network of partner warehouses across main U.S. Ports. In addition, in Los Angeles and New Jersey, we own and operate our own warehouses. With a combined warehouse space of 250,000sqft, not only are we able to perform traditional roles such as consolidation and distribution but we are able to perform value-added services such as order fulfillment, return management, repackaging, pick and pack, kitting, and light assembly."
            ]}
            src={<Img fluid={data.warehouse.childImageSharp.fluid} className="rounded h-100" alt="Warehouse and Distribution" />}
            reverse={true}
            // link={{to: "/servicios", title: "Ver más servicios"}}
            // list={[
            //     "Train + Truck.",
            //     "Ferro-Mex (Mexican Railroad).",
            //     "KCS (Kansas City Southern).",
            // ]}
        />

      <section className="container-md py-5">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h6 className="tag h5 pb-4">Experience and Relationships</h6>
            <h3 className="h1 mb-0">With a combined 30 years’ experience</h3>
          </div>

          <div className="col-sm-12 col-md-6 d-flex flex-column align-items-start justify-content-start">
            <div className="pt-3 pl-md-5 mt-45">
              <p className="pb-3">Pioneer Solutions has built a deep relationship with our customers and transportation partners; our superior access to top management and real-time communications enables us to provide our customers with tailored solutions at the lowest cost.</p>
              <Link to="/about-us" className="p-0 text-info">Go to about us</Link>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export default ServicesEnPage
